import * as anchor from '@project-serum/anchor'
import {
  Commitment,
  ConnectionConfig,
  SystemProgram,
  PublicKey,
  SYSVAR_RENT_PUBKEY,
  SYSVAR_INSTRUCTIONS_PUBKEY
} from '@solana/web3.js';


import CONFIG from "../../config";
import { makeTransaction } from '../../helper/composables/sol/connection';
import { getAssociatedTokenAddress, getTokenRecord } from '../../helper/composables';
import createAssociatedTokenAccountInstruction from '../../helper/composables';
import { getMetadataAccount } from '../../helper/composables';
import { TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { getCurrentChainTime } from '../../utils';

const TOKEN_METADATA_PROGRAM_ID = new anchor.web3.PublicKey(
  'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s',
);

export const stakingInitalize = async (connection: any, wallet: any) => {
  try {
    const provider = new anchor.Provider(connection, wallet, {
      skipPreflight: true,
      preflightCommitment: 'confirmed' as Commitment,
    } as ConnectionConfig)


    const program = new anchor.Program(CONFIG.STAKING.IDL, new PublicKey(CONFIG.STAKING.PROGRAM_ID), provider);
    const [statistic_pool] = await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from(CONFIG.STAKING.STATISTIC)], new PublicKey(CONFIG.STAKING.PROGRAM_ID)
    );

    let instructions = [], signers: any = [];
    instructions.push(program.instruction.initialize({
      accounts: {
        statistic: statistic_pool,
        admin: wallet.publicKey,
        systemProgram: SystemProgram.programId
      }
    }))

    const transaction = await makeTransaction(connection, instructions, signers, wallet.publicKey);
    return transaction

  } catch (error) {
  }
}

export const fundToken = async (connection: any, anchorWallet: any, amount: any) => {
  try {
    const provider = new anchor.Provider(connection, anchorWallet, {
      skipPreflight: true,
      preflightCommitment: 'confirmed' as Commitment,
    } as ConnectionConfig)

    const program = new anchor.Program(CONFIG.STAKING.IDL, new PublicKey(CONFIG.STAKING.PROGRAM_ID), provider);

    let instructions = [], signers: any = [];
    let [statistic] = await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from('statistic')],
      new PublicKey(CONFIG.STAKING.PROGRAM_ID)
    );
    let ataFrom = await getAssociatedTokenAddress(new PublicKey(CONFIG.TokenAddress), anchorWallet.publicKey);
    let ataTo = await getAssociatedTokenAddress(new PublicKey(CONFIG.TokenAddress), statistic, true)

    const ataToInfo = await connection.getAccountInfo(ataTo);
    if (!ataToInfo) {
      instructions.push(createAssociatedTokenAccountInstruction(anchorWallet.publicKey, ataTo, statistic, new PublicKey(CONFIG.TokenAddress)))
    }

    instructions.push(program.instruction.tokenTransfer(new anchor.BN(amount * 1000000000), {
      accounts: {
        statistic: statistic,
        admin: anchorWallet.publicKey,
        payMint: new PublicKey(CONFIG.TokenAddress),
        ataFrom,
        ataTo,
        tokenProgram: TOKEN_PROGRAM_ID,
        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        rent: SYSVAR_RENT_PUBKEY
      }
    }))
    const transaction = await makeTransaction(connection, instructions, signers, anchorWallet.publicKey)

    return transaction
  } catch (error) {

  }
}

export const stakeNft = async (anchorWallet: any, connection: any,  mintAddresses: string[]) => {
  try {
    const provider = new anchor.Provider(connection, anchorWallet, {
      skipPreflight: true,
      preflightCommitment: 'confirmed' as Commitment,
    } as ConnectionConfig)

    const program = new anchor.Program(CONFIG.STAKING.IDL, new PublicKey(CONFIG.STAKING.PROGRAM_ID), provider);

    let instructions = [], signers: any = [];
    let [pool] = await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from('pool'), anchorWallet.publicKey.toBuffer()],
      new PublicKey(CONFIG.STAKING.PROGRAM_ID)
    );

    const [statistic] = await PublicKey.findProgramAddress([
      Buffer.from('statistic')
    ], new PublicKey(CONFIG.STAKING.PROGRAM_ID));

    for(let i = 0; i < mintAddresses.length; i++) {
      let [poolData] = await anchor.web3.PublicKey.findProgramAddress(
        [Buffer.from('pool data'), anchorWallet.publicKey.toBuffer(), new PublicKey(mintAddresses[i]).toBuffer()],
        new PublicKey(CONFIG.STAKING.PROGRAM_ID)
      );

      let ataFrom = await getAssociatedTokenAddress(new PublicKey(mintAddresses[i]), anchorWallet.publicKey);

      const ataFromInfo = await connection.getAccountInfo(ataFrom);

      if (!ataFromInfo) {
        const aTokenAccounts = await connection.getParsedTokenAccountsByOwner(anchorWallet.publicKey, { mint: new PublicKey(mintAddresses[i]) });
        if (aTokenAccounts.value.length === 0) {
          return null;
        }
  
        ataFrom = aTokenAccounts.value[0].pubkey;
      }

      const metadata = await getMetadataAccount(new PublicKey(mintAddresses[i]));

      const [masterEdition] = await PublicKey.findProgramAddress([
        Buffer.from('metadata'),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        new PublicKey(mintAddresses[i]).toBuffer(),
        Buffer.from('edition')
      ], TOKEN_METADATA_PROGRAM_ID);

      const tokenRecord = await getTokenRecord(new PublicKey(mintAddresses[i]), ataFrom);
      instructions.push(program.instruction.stake({
        accounts: {
          statistic: statistic,
          pool: pool,
          poolData: poolData,
          user: anchorWallet.publicKey,
          mint: new PublicKey(mintAddresses[i]),
          metadata: metadata,
          tokenAccount: ataFrom,
          edition: masterEdition,
          tokenRecord: tokenRecord,
          delegateRecord: tokenRecord,
          sysvarInstructions: SYSVAR_INSTRUCTIONS_PUBKEY,
          sysvarRent: SYSVAR_RENT_PUBKEY,
          authorizationRulesProgram: new PublicKey('auth9SigNpDKz4sJJ1DfCTuZrZNSAgh9sFD3rboVmgg'),
          authorizationRules: new PublicKey('eBJLFYPxJmMGKuFwpDWkzxZeUrad92kZRC5BJLpzyT9'),
          metadataId: TOKEN_METADATA_PROGRAM_ID,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId
        }
      }))
    }

    const transaction = await makeTransaction(connection, instructions, signers, anchorWallet.publicKey);
    return transaction

  } catch (error) {
    return null;
  }
}

export const unStakeNft = async (anchorWallet: any, connection: any, mintAddresses: string[]) => {
  try {
    const provider = new anchor.Provider(connection, anchorWallet, {
      skipPreflight: true,
      preflightCommitment: 'confirmed' as Commitment,
    } as ConnectionConfig)

    const program = new anchor.Program(CONFIG.STAKING.IDL, new PublicKey(CONFIG.STAKING.PROGRAM_ID), provider);

    let instructions = [], signers: any = [];
    let [pool] = await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from(CONFIG.STAKING.POOL_SEED), anchorWallet.publicKey.toBuffer()],
      new PublicKey(CONFIG.STAKING.PROGRAM_ID)
    );

    const [statistic] = await PublicKey.findProgramAddress([
      Buffer.from('statistic')
    ], new PublicKey(CONFIG.STAKING.PROGRAM_ID));

    const ataFrom = await getAssociatedTokenAddress(new PublicKey(CONFIG.TokenAddress), statistic, true);
    let ataTo = await getAssociatedTokenAddress(new PublicKey(CONFIG.TokenAddress), anchorWallet.publicKey);
    const ataToInfo = await connection.getAccountInfo(ataTo);

    for(let i = 0; i < mintAddresses.length; i++) {
      let [poolData] = await anchor.web3.PublicKey.findProgramAddress(
        [Buffer.from(CONFIG.STAKING.POOL_DATA_SEED), anchorWallet.publicKey.toBuffer(), new PublicKey(mintAddresses[i]).toBuffer()],
        new PublicKey(CONFIG.STAKING.PROGRAM_ID)
      );

      const metadata = await getMetadataAccount(new PublicKey(mintAddresses[i]));
      
      if (!ataToInfo) {
        instructions.push(createAssociatedTokenAccountInstruction(anchorWallet.publicKey, ataTo, anchorWallet.publicKey, new PublicKey(CONFIG.TokenAddress)))
      }

      const mintAccount = await getAssociatedTokenAddress(new PublicKey(mintAddresses[i]), anchorWallet.publicKey);
      const mintAccountInfo = await connection.getAccountInfo(mintAccount);
      if (!mintAccountInfo) {
        instructions.push(createAssociatedTokenAccountInstruction(anchorWallet.publicKey, mintAccount, pool, new PublicKey(mintAddresses[i])))
      }

      const [masterEdition] = await PublicKey.findProgramAddress([
        Buffer.from('metadata'),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        new PublicKey(mintAddresses[i]).toBuffer(),
        Buffer.from('edition')
      ], TOKEN_METADATA_PROGRAM_ID);
      const tokenRecord = await getTokenRecord(new PublicKey(mintAddresses[i]), mintAccount);

      instructions.push(program.instruction.unstake({
        accounts: {
          statistic: statistic,
          pool: pool,
          poolData: poolData,
          user: anchorWallet.publicKey,
          mint: new PublicKey(mintAddresses[i]),
          metadata,
          payMint: new PublicKey(CONFIG.TokenAddress),
          ataFrom,
          ataTo,
          tokenAccount: mintAccount,
          edition: masterEdition,
          tokenRecord: tokenRecord,
          delegateRecord: tokenRecord,
          sysvarInstructions: SYSVAR_INSTRUCTIONS_PUBKEY,
          sysvarRent: SYSVAR_RENT_PUBKEY,
          authorizationRulesProgram: new PublicKey('auth9SigNpDKz4sJJ1DfCTuZrZNSAgh9sFD3rboVmgg'),
          authorizationRules: new PublicKey('eBJLFYPxJmMGKuFwpDWkzxZeUrad92kZRC5BJLpzyT9'),
          metadataId: TOKEN_METADATA_PROGRAM_ID,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId
        }
      }))
    }

    const transaction = await makeTransaction(connection, instructions, signers, anchorWallet.publicKey);

    return transaction
  } catch (error) {
    return null;
  }
}

export const claimNfts = async (
  anchorWallet: any,
  connection: any,
  mintAddresses: string[],
) => {
  try {
    const provider = new anchor.Provider(connection, anchorWallet, {
      skipPreflight: true,
      preflightCommitment: 'confirmed' as Commitment,
    } as ConnectionConfig)

    const program = new anchor.Program(CONFIG.STAKING.IDL, new PublicKey(CONFIG.STAKING.PROGRAM_ID), provider);
    let instructions = [], signers: any = [];

    let [pool] = await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from(CONFIG.STAKING.POOL_SEED), anchorWallet.publicKey.toBuffer()],
      new PublicKey(CONFIG.STAKING.PROGRAM_ID)
    );

    for(let i = 0; i < mintAddresses.length; i++) {
      let [poolData] = await anchor.web3.PublicKey.findProgramAddress(
        [Buffer.from(CONFIG.STAKING.POOL_DATA_SEED), anchorWallet.publicKey.toBuffer(), new PublicKey(mintAddresses[i]).toBuffer()],
        new PublicKey(CONFIG.STAKING.PROGRAM_ID)
      );


      const [statistic] = await PublicKey.findProgramAddress([
        Buffer.from('statistic')
      ], new PublicKey(CONFIG.STAKING.PROGRAM_ID));

      const ataFrom = await getAssociatedTokenAddress(new PublicKey(CONFIG.TokenAddress), statistic, true);

      let ataTo = await getAssociatedTokenAddress(new PublicKey(CONFIG.TokenAddress), anchorWallet.publicKey);

      const ataToInfo = await connection.getAccountInfo(ataTo);

      if (!ataToInfo) {
        instructions.push(createAssociatedTokenAccountInstruction(anchorWallet.publicKey, ataTo, anchorWallet.publicKey, new PublicKey(CONFIG.TokenAddress)))
      }
  
      instructions.push(program.instruction.claim({
        accounts: {
          statistic: statistic,
          user: anchorWallet.publicKey,
          mint: new PublicKey(mintAddresses[i]),
          pool: pool,
          poolData: poolData,
          payMint: new PublicKey(CONFIG.TokenAddress),
          ataFrom,
          ataTo,
          tokenProgram: TOKEN_PROGRAM_ID,
        }
      }));

    }

    return await makeTransaction(connection, instructions, signers, anchorWallet.publicKey);

  } catch (error) {
    console.log(`claimNfts error`, error);
    return null;
  }
}

export const getRewardAmount = async (
  anchorWallet: any,
  connection: any,
  mint: any
) => {
  try {
    const provider = new anchor.Provider(connection, anchorWallet, {
      skipPreflight: true,
      preflightCommitment: 'confirmed' as Commitment,
    } as ConnectionConfig)

    const program = new anchor.Program(CONFIG.STAKING.IDL, new PublicKey(CONFIG.STAKING.PROGRAM_ID), provider);

    let [poolData] = await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from(CONFIG.STAKING.POOL_DATA_SEED), anchorWallet.publicKey.toBuffer(), new PublicKey(mint).toBuffer()],
      new PublicKey(CONFIG.STAKING.PROGRAM_ID)
    );

    const connect_poolData = await connection.getAccountInfo(poolData);
    let totalReward = 0, dailyYield = 0;
    if (connect_poolData) {
      const getPoolData = await program.account.poolData.fetch(poolData);
      let startTIme = getPoolData.startTime;

      let endTime: any = CONFIG.STAKING.START_TIME
      const currentTime = await getCurrentChainTime();
      if (!currentTime) {
        return {
          totalReward,
          dailyYield
        }
      }
      // totalReward = getPool.totalReward.toNumber() / CONFIG.DECIMAL;
      const days = CONFIG.STAKING.DAYS;
      if (startTIme < currentTime) {
        for (let i = 0; i < days.length; i++) {
          endTime = endTime + CONFIG.STAKING.DAY_TIME * days[i];
          if (startTIme <= endTime) {
            if (endTime < currentTime) {
              if (i < 5) {
                totalReward += (CONFIG.STAKING.DAILY_REWARD - i) * (endTime - startTIme) * (CONFIG.DECIMAL) / CONFIG.STAKING.DAY_TIME;

                dailyYield = CONFIG.STAKING.DAILY_REWARD - i;
                startTIme = endTime
              } else {
                totalReward += 5 * (endTime - startTIme) * (CONFIG.DECIMAL) / CONFIG.STAKING.DAY_TIME
                dailyYield = 5;
                startTIme = endTime
              }
            } else {
              if (i < 5) {
                totalReward += (CONFIG.STAKING.DAILY_REWARD - i) * (currentTime - startTIme) * (CONFIG.DECIMAL) / CONFIG.STAKING.DAY_TIME;
                dailyYield = CONFIG.STAKING.DAILY_REWARD - i;
                break;
              } else {
                totalReward += 5 * (currentTime - startTIme) * (CONFIG.DECIMAL) / CONFIG.STAKING.DAY_TIME;
                dailyYield = 5;
                break;
              }
            }
          }
        }
      }
    }
    return {
      totalReward,
      dailyYield
    }

  } catch (error) {

  }
}
