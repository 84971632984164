import { useEffect, useState } from "react";
import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react";
import Navbar from "../../components/Navbar"
import { fundToken, stakingInitalize } from "../../services/contracts/staking";
import { confirmTx } from "../../helper/composables/sol/confirm";
import { toast } from "react-hot-toast";
import {
  Connection,
  Commitment,
  ConnectionConfig,
  PublicKey,
} from '@solana/web3.js';

import CONFIG from "../../config";
import { getAssociatedTokenAddress } from "@solana/spl-token";

const AdminStaking = () => {
  const [isLoading, setLoading] = useState(false);
  const [isNoInitalStatus, setNoInitalStatus] = useState(false)
  const [tokenAmount, setTokenAmount] = useState(0)
  const [walletTokenAmount, setWalletTokenAmount] = useState(0)
  const anchorWallet: any = useAnchorWallet();
  const { connection } = useConnection()

  const handleInitalize = async () => {
    try {
      if (!isNoInitalStatus) {
        toast.error(`Already Initalized`)
        return
      }
      setLoading(true)
      const connection = new Connection(CONFIG.CLUSTER_API, {
        skipPreflight: true,
        preflightCommitment: 'confirmed' as Commitment,
      } as ConnectionConfig);

      const getTx: any = await stakingInitalize(connection, anchorWallet)

      const res = await confirmTx(getTx, anchorWallet, connection)
      if (res) {
        toast.success(`Successfully Initalized`)
        setNoInitalStatus(false)
      }

      setLoading(false)
    } catch (error) {

      setLoading(false)
    }
  }

  const handleFundToken = async () => {
    try {
      if (tokenAmount <= 0) {
        toast.error('TokenAmount must bigger than 0')
        return
      }
      if (walletTokenAmount < tokenAmount) {
        toast.error(`TokenAmount must smaller than ${walletTokenAmount}`)
        return
      }
      if (walletTokenAmount <= 0) {
        toast.error(`No Exist Token`)
        return
      }
      setLoading(true)
      const getTx: any = await fundToken(connection, anchorWallet, tokenAmount)
      const res = await confirmTx(getTx, anchorWallet, connection)

      if (res) {
        toast.success(`Successfully Fund`)
        setWalletTokenAmount(walletTokenAmount - tokenAmount)
      } else {
        toast.error('Error')
      }
      setLoading(false)
    } catch (error) {

      toast.error('Error')

      setLoading(false)
    }
  }

  useEffect(() => {
    (
      async () => {
        try {
          if (!anchorWallet) return
          setLoading(true)
          const connection = new Connection(CONFIG.CLUSTER_API, {
            skipPreflight: true,
            preflightCommitment: 'confirmed' as Commitment,
          } as ConnectionConfig);

          const get_tokenAccount = await getAssociatedTokenAddress(new PublicKey(CONFIG.TokenAddress), anchorWallet!.publicKey)

          const getTokenInfo: any = await connection.getParsedAccountInfo(get_tokenAccount)
          const token_count = getTokenInfo?.value?.data?.parsed?.info?.tokenAmount?.uiAmount;
          setWalletTokenAmount(token_count)

          const [statistic] = await PublicKey.findProgramAddress(
            [Buffer.from('statistic')], new PublicKey(CONFIG.STAKING.PROGRAM_ID));

          const get_statistics = await connection.getAccountInfo(statistic)
          if (!get_statistics) {
            setNoInitalStatus(true)
            setLoading(false)
            return
          }

          setLoading(false)
        } catch (error) {

          setLoading(false)
        }
      }
    )()
  }, [anchorWallet])

  return (
    <div className="bg-black" >
      {
        isLoading ?
          <div id="preloader"></div> :
          <div id="preloader" style={{ display: "none" }}></div>
      }
      <Navbar />
      <div
        onClick={handleInitalize}
        className={`bg-white flex items-center py-3 px-5 rounded-[0.7rem] mt-12 max-w-fit mx-[auto] my-0 w-full
        ${isNoInitalStatus ? `cursor-pointer` : `cursor-no-drop`}`}
      >
        <span>Initalize</span>
      </div>
      <div className="flex items-center gap-8 text-white w-full justify-center mt-[24px] " >
        <p>Wallet Token Amount</p>
        <p>{walletTokenAmount}</p>
      </div>
      {
        !isNoInitalStatus && <div className="flex flex-col gap-4" >
          <div className="w-full mt-8 justify-center flex items-center gap-4 " >
            <p className="text-white" >Token Fund Amount</p>
            <input
              value={tokenAmount}
              onChange={(e: any) => setTokenAmount(e.target.value)}
              className="p-[8px] rounded-[8px] "
            />
          </div>
          <div
            onClick={handleFundToken}
            className={`bg-white flex items-center py-3 px-5 rounded-[0.7rem] mt-6 max-w-fit mx-[auto] my-0 w-full cursor-pointer`}
          >
            <span>Fund</span>
          </div>

        </div>
      }

    </div>
  )
}

export default AdminStaking