import * as STAKING_IDL from '../constants/idl/staking'

export const Backend_URL = 'http://10.10.17.151:5555'

export const API_URL = `${Backend_URL}/api`

export const CLUSTER = 'devnet';
// export const CLUSTER_API = 'https://metaplex.devnet.rpcpool.com/';
export const CLUSTER_API = 'https://api.devnet.solana.com/';

export const SOLANA_NETWORK = 'devnet';
export const DECIMAL = 1000000000
export const TOAST_TIME_OUT = 2000;
export const MAX_INSTRUCTIONS = 3;

export const STAKING = {
  PROGRAM_ID: 'A8FFovyJCj8J235sKfUiorYZB9o8qoLSG3EzGG554Ws',
  STATISTIC: 'statistic',
  POOL_SEED: 'pool',
  POOL_DATA_SEED: 'pool data',
  IDL: STAKING_IDL.IDL,
  message: 'Staking Message',
  CREATOR_ADDRESS: 'DyKv1WTgSyyPuHLi3SmFKMcoUDDif2KvRr55N8ZcU2oV',
  START_TIME: 1682899200,// Mon May 01 2023 00:00:00 GMT+0000
  DAYS: [31, 30, 31, 31, 30, 31, 30, 31, 31, 29, 31, 30, 31],
  DAILY_REWARD: 10,
  DAY_TIME: 86400,
  TOTAL_AMOUNT: 6666,
}

export const TokenAddress = '55u5jMiJtwsvyo834R2mmcrxMGu7x2KvbrguJNbHFnEJ'

export const INTERVAL = 6 * 1000;
export const ADMIN_WALLET = "3ttYrBAp5D2sTG2gaBjg8EtrZecqBQSBuFRhsqHWPYxX"
export const SIGN_KEY = 'VERIFY WALLET';
export const MaginEdenCollectionApi = "https://toolkit.kongvszillanft.workers.dev/?u=https://api-mainnet.magiceden.dev/v2/collections/coode/stats"

export const GET_SOLALA_INFO = 'https://api.solscan.io/market?symbol=SOL&cluster='