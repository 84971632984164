
import * as anchor from "@project-serum/anchor";
import { WalletContextState } from "@solana/wallet-adapter-react";
import { Commitment, Connection, ConnectionConfig, PublicKey } from "@solana/web3.js";
import * as borsh from 'borsh';
import { Metadata, METADATA_SCHEMA } from './schema';
import {
  resolveToWalletAddress,
  getParsedNftAccountsByOwner,
} from "@nfteyez/sol-rayz";
import {
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import CONFIG from '../config';

const { CLUSTER_API } = CONFIG;


export const CANDY_MACHINE_PROGRAM = new anchor.web3.PublicKey(
  "cndyAnrLdpjq1Ssp1z8xxDsB8dxe7u4HL5Nxi2K5WXZ"
);

const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new anchor.web3.PublicKey(
  "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
);

const METADATA_PROGRAM_ID_PUBLIC_KEY = new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s')


export const getSignedMessage = async (wallet: WalletContextState, message: string) => {
  try {
    if (!wallet.publicKey) return null;
    const signedMessage = await wallet.signMessage!(new TextEncoder().encode(message));
    return signedMessage;
  }
  catch (error) {
    return null;
  }
}

export const datetimeLocal = (datetime: Date) => {
  let month = datetime.getMonth() + 1;
  let day = datetime.getDate();
  let hour = datetime.getHours();
  let min = datetime.getMinutes();
  const result = `${datetime.getFullYear()}-${month >= 10 ? month : `0${month}`}-${day >= 10 ? day : `0${day}`}T${hour >= 10 ? hour : `0${hour}`}:${min >= 10 ? min : `0${min}`}`
  return result;
}

export async function decodeMetadata(buffer: Buffer) {
  const metadata = borsh.deserializeUnchecked(
    METADATA_SCHEMA,
    Metadata,
    buffer,
  );
  metadata.data.name = metadata.data.name.replace(METADATA_REPLACE, '');
  metadata.data.uri = metadata.data.uri.replace(METADATA_REPLACE, '');
  metadata.data.symbol = metadata.data.symbol.replace(METADATA_REPLACE, '');
  return metadata;
}

const METADATA_REPLACE = new RegExp('\u0000', 'g');

export const getNftMetadata = async (uri: string) => {

  const result =
    await fetch(uri)
      .then(res => res.json())
      .catch(() => null)

  return result;
}

export const connection = new Connection(CLUSTER_API, {
  skipPreflight: true,
  preflightCommitment: 'confirmed' as Commitment,
} as ConnectionConfig);

export async function getNftMetaDataByMint(mintAddress: string) {
  const web3 = require('@solana/web3.js');

  let connection = new web3.Connection(
    web3.clusterApiUrl('devnet'),
    'confirmed',
  );
  // const { connection } = useConnection();

  const program = await PublicKey.findProgramAddress(
    [
      Buffer.from('metadata'),
      METADATA_PROGRAM_ID_PUBLIC_KEY.toBytes(),
      new PublicKey(mintAddress).toBytes()
    ],
    METADATA_PROGRAM_ID_PUBLIC_KEY
  )

  let accountInfo = await connection.getAccountInfo(program[0])
  let metadata = await decodeMetadata(accountInfo.data)

  const result =
    await fetch(metadata.data.uri)
      .then(res => res.json())
      .catch(() => null)

  return { ...metadata, ...result };
}


const getTokenWallet = async (
  wallet: anchor.web3.PublicKey,
  mint: anchor.web3.PublicKey
) => {
  return (
    await anchor.web3.PublicKey.findProgramAddress(
      [wallet.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
      SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
    )
  )[0];
};

export async function getNftsForOwner(
  owner: any
) {
  if (owner) {
    const address = owner
    const publicAddress = await resolveToWalletAddress({
      text: address, connection
    });

    const nftArray = await getParsedNftAccountsByOwner({
      publicAddress, connection
    });

    const getCollection = nftArray.filter((item) => {
      return item?.data?.creators && item?.data?.creators[0]?.address === CONFIG.STAKING.CREATOR_ADDRESS
    })

    const getMetadata = await Promise.all(getCollection.map((item) => {
      let meta = getNftMetadata(item?.data?.uri);
      return meta
    }))

    const get_allTokens = await Promise.all(getCollection.map(async (item) => {
      const token_info = await getTokenWallet(new PublicKey(address), new anchor.web3.PublicKey(item.mint))
      return token_info?.toString()
    }))


    const getNewCollection: any = getCollection.map((item, idx) => {
      return { ...item, metadata: getMetadata[idx], account: get_allTokens[idx], selected: false }
    })

    return getNewCollection
  }
}

export const getCurrentChainTime = async () => {
  const slot = await connection.getSlot('confirmed');
  const curChainTime = await connection.getBlockTime(slot);
  return curChainTime;
}
