import React, { useState, useEffect } from "react";
import { useConnection } from "@solana/wallet-adapter-react";
import * as anchor from '@project-serum/anchor'
import axios from "axios";

import { Commitment, ConnectionConfig, PublicKey } from "@solana/web3.js";

import Navbar from "../../components/Navbar";
import StakedNFT from "../../assets/Subtract-Staked-NFT.png";
import coodeStaked from "../../assets/Subtract-coode-staked.png";
import StakedValue from "../../assets/Subtract-Staked-Value.png";
import StakingMenus from "../../components/StakingMenus";
import TwitterBlack from "../../assets/Twitter-black.png";
import DiscordBlack from "../../assets/Discord-Black.png";
import MediumBlack from "../../assets/Medium.png";
import CONFIG from "../../config";
import commonService from "../../services/common.service";
import { GET_SOLALA_INFO, MaginEdenCollectionApi } from "../../config/dev";

const Staking: React.FC = () => {
  const { connection } = useConnection()

  const [isLoading, setLoading] = useState(false);
  const [stakedCount, setStakedCount] = useState(0)
  const [solUsdPriceFloor, setSolUsdFloorPrice] = useState(0)

  useEffect(() => {
    (
      async () => {
        try {
          setLoading(true)
          const tempWallet: any = anchor.web3.Keypair.generate().publicKey
          const provider = new anchor.Provider(connection, tempWallet, {
            skipPreflight: true,
            preflightCommitment: 'confirmed' as Commitment,
          } as ConnectionConfig)
          const programNft = new anchor.Program(CONFIG.STAKING.IDL, new PublicKey(CONFIG.STAKING.PROGRAM_ID), provider);

          let [statisticPool]: any = await anchor.web3.PublicKey.findProgramAddress(
            [Buffer.from(CONFIG.STAKING.STATISTIC)],
            new PublicKey(CONFIG.STAKING.PROGRAM_ID)
          );
          const statisticData = await programNft.account.statistic.fetch(statisticPool)

          setStakedCount(statisticData.stakedCount)


          const getCollect = await axios.get(MaginEdenCollectionApi)

          const floorPrice = getCollect?.data?.floorPrice / CONFIG.DECIMAL
          const solNet: any = await commonService({
            method: `get`,
            route: GET_SOLALA_INFO
          })
          const usdPrice = solNet?.priceUsdt
          setSolUsdFloorPrice(floorPrice * usdPrice)
          setLoading(false)

        } catch (error) {
          setLoading(false)

        }
      }
    )()
  }, [])

  return (
    <div>
      {
        isLoading ?
          <div id="preloader"></div> :
          <div id="preloader" style={{ display: "none" }}></div>
      }
      <Navbar />
      <StakingMenus />
      <div className="max-w-[550px] m-auto">
        <div className="mt-6">
          <div className="border border-[#606060] rounded-md flex overflow-hidden">
            <div className="flex items-center justify-center basis-[25%] py-6">
              <img src={StakedNFT} alt="StakedNFT" className="max-w-[75px]" />
            </div>
            <div className="bg-white flex items-center basis-[75%] staking-clip-box">
              <div className="ml-16">
                <h1 className="text-2xl">Total Staked NFTs</h1>
                <h1 className="text-4xl mt-1">{stakedCount}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="border border-[#606060] rounded-md flex overflow-hidden">
            <div className="flex items-center justify-center basis-[25%] py-6">
              <img
                src={coodeStaked}
                alt="coodeStaked"
                className="max-w-[75px]"
              />
            </div>
            <div className="bg-white flex items-center basis-[75%] staking-clip-box">
              <div className="ml-16">
                <h1 className="text-2xl">% of COODE Staked</h1>
                <h1 className="text-4xl mt-1">{stakedCount ? (100 * stakedCount / CONFIG.STAKING.TOTAL_AMOUNT).toFixed(3) : 0}%</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="border border-[#606060] rounded-md flex overflow-hidden">
            <div className="flex items-center justify-center basis-[25%] py-6">
              <img
                src={StakedValue}
                alt="StakedValue"
                className="max-w-[75px]"
              />
            </div>
            <div className="bg-white flex items-center basis-[75%] staking-clip-box">
              <div className="ml-16">
                <h1 className="text-2xl">Total Value Locked</h1>
                <h1 className="text-4xl mt-1">${(solUsdPriceFloor * stakedCount).toFixed(2)}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-center pb-16">
          <button type="button" className="py-3 px-4 rounded-md bg-white">
            Dashboard
          </button>
        </div>
      </div>
      <ul className="bg-white fixed bottom-0 right-0">
        <li>
          <a href="https://magiceden.io/marketplace/coode" target="_blank"  className="px-3 py-3 block">
            <img src={MediumBlack} alt="MediumBlack" />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/COODENFTS" target="_blank" className="px-3 py-3 block">
            <img src={TwitterBlack} alt="TwitterBlack" />
          </a>
        </li>
        <li>
          <a href="https://discord.gg/nJNNeUApsn" target="_blank" className="px-3 py-3 block">
            <img src={DiscordBlack} alt="DiscordBlack" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Staking;
