import axios, {
  AxiosResponse,
  AxiosError,
  AxiosPromise,
  Method,
  AxiosRequestHeaders,
} from "axios";


type AxiosReturn = Promise<AxiosPromise<AxiosResponse | AxiosError>>;

interface ServiceParams {
  method: Method;
  route: string;
  headerCred?: {
    autherization?: string;
    contentType?: string;
  };
  data?: object | string;
}

function setHeader(
  header:
    | {
      autherization?: string;
      contentType?: string;
    }
    | undefined
): object {
  return {
    Accept: "*/*",
    "Content-Type": header?.contentType || "application/json",
    "Access-Control-Allow-Origin": "http://localhost:3000",
    "Access-Control-Allow-Credentials": "true",
    //`Bearer ${header?.autherization}`,
  };
}


export default async ({
  method,
  route,
  headerCred,
  data,
}: ServiceParams): Promise<AxiosPromise<AxiosResponse | AxiosError>> => {
  const headers = setHeader(headerCred) as AxiosRequestHeaders;

  const response: AxiosResponse = await axios({
    method,
    url: route,
    headers,
    data,
  });

  if (response.data?.data) {
    return response.data.data;
  }
  return response.data;
};
