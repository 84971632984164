import { toast } from "react-hot-toast"

export const ProfileValidation = (profile: any) => {
  if (profile.dreamNft === ``) {
    toast.error(`Please Input your dream NFT`);
    return false
  }

  if (profile.dreamVacation === ``) {
    toast.error(`Please Input your dream vacation destination`);
    return false
  }

  if (profile.travel === ``) {
    toast.error(`Please Input travel field`);
    return false
  }

  if (profile.watchKind === ``) {
    toast.error(`Please Input kind of watch`);
    return false
  }

  if (profile.petKind === ``) {
    toast.error(`Please Input kind of pet`);
    return false
  }

  return true
}