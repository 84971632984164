import { useMemo } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  GlowWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
  LedgerWalletAdapter
} from "@solana/wallet-adapter-wallets";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { Toaster } from 'react-hot-toast';

import CONFIG from "./../src/config";

import "./App.css";

import Staking from "./pages/Staking/Staking";
import Dashboard from "./pages/Dashboard/Dashboard";
import AdminStaking from "./pages/Admin"
import Profile from "./pages/Profile";

require("@solana/wallet-adapter-react-ui/styles.css"); // Default styles that can be overridden by your app

const network = CONFIG.SOLANA_NETWORK as WalletAdapterNetwork;
const rpcHost = CONFIG.CLUSTER_API;

const theme = createTheme({
  palette: {
    type: "dark",
  },
  overrides: {
    MuiButtonBase: {
      root: {
        justifyContent: "flex-start",
      },
    },
    MuiButton: {
      root: {
        textTransform: undefined,
        padding: "12px 16px",
      },
      startIcon: {
        marginRight: 8,
      },
      endIcon: {
        marginLeft: 8,
      },
    },
  },
});

const App = () => {
  const endpoint = rpcHost;

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter()
    ],
    // eslint-disable-next-line
    [network]
  );

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect>
            <WalletModalProvider>
              <Routes>
                <Route path="/admin" element={<AdminStaking />} />
                <Route path="/profile" element={<Profile />} />

                <Route path="/" element={<Staking />} />
                <Route path="/dashboard" element={<Dashboard />} />

              </Routes>
            </WalletModalProvider>
          </WalletProvider>
        </ConnectionProvider>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
