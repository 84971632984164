import React from "react";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { Link } from "react-router-dom";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import Logo from "../assets/Logo.png";
import CONFIG from "../config";

const Navbar: React.FC = () => {
  const anchorWallet: any = useAnchorWallet()

  return (
    <div className="border-white border-b-2">
      <div className="flex justify-between items-center py-5 px-4">
        <Link to="/" className="sm:max-w-[165px] max-w-[100px]">
          <img src={Logo} alt="l" />
        </Link>
        <ul className="flex items-center gap-[16px] ">
          {
            anchorWallet?.publicKey.toBase58() === CONFIG.ADMIN_WALLET && <li>
              <Link
                to="/admin"
                className="text-white text-base font-archia hover:opacity-95 transition-all"
              >
                Admin
              </Link>
            </li>
          }
          <Link
            to='/profile'
            className="text-white text-base font-archia hover:opacity-95 transition-all" >
            Profile
          </Link>

          <li >
            <WalletMultiButton startIcon={undefined} />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
