import * as STAKING_IDL from '../constants/idl/staking'

export const Backend_URL = 'https://coodebe.herokuapp.com'

export const API_URL = `${Backend_URL}/api`

export const CLUSTER = 'mainnet-beta';
export const CLUSTER_API = 'https://rpc.hellomoon.io/15b3c970-4cdc-4718-ac26-3896d5422fb6';

export const SOLANA_NETWORK = 'mainnet-beta';
export const DECIMAL = 1000000000
export const TOAST_TIME_OUT = 2000;
export const MAX_INSTRUCTIONS = 1;

export const STAKING = {
  PROGRAM_ID: '6y9Y19eBKyovffBNE9N4hpL7xQ5Yaa2KWo98aQ7Mcs1A',
  STATISTIC: 'statistic',
  POOL_SEED: 'pool',
  POOL_DATA_SEED: 'pool data',
  IDL: STAKING_IDL.IDL,
  message: 'Staking Message',
  CREATOR_ADDRESS: 'CaYsVNkgS5yBMK1BVTmbpapumjbyXNsBFZ2W1zbbk374',
  START_TIME: 1682899200,// Mon May 01 2023 00:00:00 GMT+0000
  DAYS: [31, 30, 31, 31, 30, 31, 30, 31, 31, 29, 31, 30, 31],
  DAILY_REWARD: 10,
  DAY_TIME: 86400,
  TOTAL_AMOUNT: 5000,
}

export const TokenAddress = '9aeip1QTVXNUVbcQ14UMDssmxNv4ve7sg8cVyfHoeNmT'
// export const TokenAddress = 'DQAZhAzyPcuyCB7GKaLBrM6qL5m2PsoULZ1vYjxsS1hq'

export const INTERVAL = 6 * 1000;
export const ADMIN_WALLET = "DBadiSE9HsUhKqSmcNnsVtzUuwAeLtGFVCKY6LC1W9us"
// export const ADMIN_WALLET = "2RRQVzn6e7dcZrLNQnN727bbWrhxYaJmnVR1PrhFeMW6"
export const SIGN_KEY = 'VERIFY WALLET';
export const MaginEdenCollectionApi = "https://toolkit.kongvszillanft.workers.dev/?u=https://api-mainnet.magiceden.dev/v2/collections/coode/stats"
export const GET_SOLALA_INFO = 'https://api.solscan.io/market?symbol=SOL&cluster='

