import * as anchor from '@project-serum/anchor'
import React, { useState, useEffect } from "react";
import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react";
import axios from "axios";
import {
  Commitment,
  ConnectionConfig,
  PublicKey
} from "@solana/web3.js";
import { toast } from "react-hot-toast";

import Navbar from "../../components/Navbar";
import StakingMenus from "../../components/StakingMenus";
import LockedIcon from "../../assets/Staking-Locked-icon.png";
import DollarIcon from "../../assets/Dollar-Icon.png";
import WalletIcon from "../../assets/Wallet-Icons.png";
import YieldIcons from "../../assets/Yield-Icons.png";

import StakingComponent from "../../components/Staking/Staking";
import UnStakingComponent from "../../components/Staking/Unstaking";
import { getNftsForOwner } from "../../utils";
import CONFIG from "../../config";
import { claimNfts, getRewardAmount, stakeNft, unStakeNft } from "../../services/contracts/staking";
import { signAndSendTransactions } from "../../helper/composables/sol/connection";
import { getAssociatedTokenAddress } from '../../helper/composables';
import commonService from "../../services/common.service";
import { GET_SOLALA_INFO, MaginEdenCollectionApi } from "../../config/dev";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Dashboard: React.FC = () => {
  const anchorWallet: any = useAnchorWallet();
  const { connection } = useConnection();
  const [isLoading, setLoading] = useState(false);

  const [stakedNfts, setStakedNfts] = useState<any>([]);
  const [unStakedNfts, setUnstakedNfts] = useState<any>([]);
  const [stakingInfo, setStakingInfo] = useState({
    totalCount: 0,
    ownerCount: 0,
    dailyYield: 0
  })
  const [coodeTokenBalance, setCoodeTokenBalance] = useState(0)

  const [solUsdPriceFloor, setSolUsdFloorPrice] = useState(0)

  const handleUnStakeAll = async () => {
    try {
      if (!stakedNfts || stakedNfts.length === 0) {
        toast.error(`No exist Staked NFTs`)
        return
      }
      setLoading(true)
      let transactions: any[] = [];
      let getDailyYield: any = 0;

      let mintAddresses: string[] = [];
      let getTx = null;
      let nfts = stakedNfts;

      if(Array.isArray(nfts) && nfts.length > 0) {
        mintAddresses.push(nfts[0].mint);
        getTx = await unStakeNft(anchorWallet, connection, mintAddresses);

        if(getTx) {
          transactions.push(getTx);
        }

        mintAddresses = [];
        getTx = null;
        nfts.shift();
      }

      for (let i = 0; i < nfts.length; i++) {
        
        if(i != 0 && i % CONFIG.MAX_INSTRUCTIONS == 0) {
          getTx = await unStakeNft(anchorWallet, connection, mintAddresses);
          if(getTx) {
            transactions.push(getTx);
          }

          mintAddresses = [];
          mintAddresses.push(nfts[i].mint);
        }
        else {
          mintAddresses.push(nfts[i].mint);
        }

        const rewardTx = await getRewardAmount(anchorWallet, connection, nfts[i].mint)
        getDailyYield += rewardTx?.dailyYield;
      }

      if(mintAddresses.length > 0) {
        getTx = await unStakeNft(anchorWallet, connection, mintAddresses);
        if(getTx) {
          transactions.push(getTx);
        }
      }

      const result = await signAndSendTransactions(connection, anchorWallet!, transactions);

      if (result.length) {
        toast.success('Successfully All NFTs is UnStaked ');
        setUnstakedNfts([...unStakedNfts, ...nfts])
        setStakingInfo({
          ...stakingInfo,
          totalCount: stakingInfo.totalCount - nfts.length,
          ownerCount: stakingInfo.ownerCount - stakedNfts.length,
          dailyYield: stakingInfo.dailyYield - getDailyYield,
        })
        setStakedNfts([])
      } else {
        toast.error('Error stake')
      }
      setLoading(false)

    } catch (error) {
      console.log(`staking error in handleUnStakeAll`, error);

      toast.error('Error UnStake')
      setLoading(false)
    }
  }

  const handleStakeAll = async () => {
    try {
      if (!unStakedNfts || unStakedNfts.length === 0) {
        toast.error(`No exist NFT in your wallet`)
        return
      }
      setLoading(true)
      let transactions: any[] = [];
      let getDailyYield: any = 0;

      let mintAddresses: string[] = [];
      let getTx = null;

      for (let i = 0; i < unStakedNfts.length; i++) {
        if(i != 0 && i % CONFIG.MAX_INSTRUCTIONS == 0) {
          getTx = await stakeNft(anchorWallet, connection, mintAddresses);
          if(getTx) {
            transactions.push(getTx);
          }

          mintAddresses = [];
          mintAddresses.push(unStakedNfts[i].mint);
        }
        else {
          mintAddresses.push(unStakedNfts[i].mint);
        }

        const rewardTx = await getRewardAmount(anchorWallet, connection, unStakedNfts[i].mint)
        getDailyYield += rewardTx?.dailyYield;
      }

      if(mintAddresses.length > 0) {
        getTx = await stakeNft(anchorWallet, connection, mintAddresses);
        if(getTx) {
          transactions.push(getTx);
        }
      }

      const result = await signAndSendTransactions(connection, anchorWallet!, transactions)
      if (result.length) {
        toast.success('Successfully All NFTs is Staked ');
        setStakedNfts([...stakedNfts, ...unStakedNfts])
        setStakingInfo({
          ...stakingInfo,
          totalCount: stakingInfo.totalCount + unStakedNfts.length,
          ownerCount: stakingInfo.ownerCount + unStakedNfts.length,
          dailyYield: stakingInfo.dailyYield + getDailyYield
        })
        setUnstakedNfts([])
      } else {
        toast.error('Error stake')
      }
      setLoading(false)

    } catch (error) {
      console.log(`staking error in handleStakeAll`, error);
      toast.error('Error stake')
      setLoading(false)
    }
  }

  const handleClaim = async () => {
    try {
      setLoading(true)

      let transactions: any[] = [];
      let rewardAmount = 0

      let mintAddresses: string[] = [];
      let getTx = null;
      let nfts = stakedNfts;

      if(Array.isArray(nfts) && nfts.length > 0) {
        mintAddresses.push(nfts[0].mint);
        getTx = await claimNfts(anchorWallet, connection, mintAddresses);

        if(getTx) {
          transactions.push(getTx);
        }

        mintAddresses = [];
        getTx = null;
        nfts.shift();
      }

      for (let i = 0; i < nfts.length; i++) {
        
        if(i != 0 && i % CONFIG.MAX_INSTRUCTIONS == 0) {
          getTx = await claimNfts(anchorWallet, connection, mintAddresses);
          if(getTx) {
            transactions.push(getTx);
          }

          mintAddresses = [];
          mintAddresses.push(nfts[i].mint);
        }
        else {
          mintAddresses.push(nfts[i].mint);
        }
      }

      if(mintAddresses.length > 0) {
        getTx = await claimNfts(anchorWallet, connection, mintAddresses);
        if(getTx) {
          transactions.push(getTx);
        }
      }

      const result = await signAndSendTransactions(connection, anchorWallet!, transactions);
      if (result.length > 0) {
        toast.success('Successfully Claimed')
        setCoodeTokenBalance(0);
      } else {
        toast.error('Error Claim')
      }
      setLoading(false)
    } catch (error) {

      setLoading(false)
      toast.error('Error Claim')
    }
  }

  useEffect(() => {
    (
      async () => {
        console.log("fixed when claim with tx per one");
        try {
          if (!anchorWallet) return
          setLoading(true)
          const nftsForOwner = await getNftsForOwner(anchorWallet?.publicKey?.toString());
          let stakedNfts: any = [];
          let unstakedNfts: any = []
          let [pool] = await anchor.web3.PublicKey.findProgramAddress(
            [Buffer.from('pool'), anchorWallet.publicKey.toBuffer()],
            new PublicKey(CONFIG.STAKING.PROGRAM_ID)
          )

          await Promise.all(nftsForOwner.map(async (item: any) => {
            const accountInfo: any = await connection.getParsedAccountInfo(new PublicKey(item.account));
            //@ts-ignore
            if (accountInfo.value?.data.parsed.info.state === 'frozen' &&
              accountInfo.value?.data.parsed.info.delegate === pool.toString()
            ) {
              stakedNfts.push(item);
            }
            else {
              unstakedNfts.push(item);
            }
          }))
          setStakedNfts(stakedNfts);
          setUnstakedNfts(unstakedNfts);

          const provider = new anchor.Provider(connection, anchorWallet, {
            skipPreflight: true,
            preflightCommitment: 'confirmed' as Commitment,
          } as ConnectionConfig)

          const program = new anchor.Program(CONFIG.STAKING.IDL, new PublicKey(CONFIG.STAKING.PROGRAM_ID), provider);
          const [statistic] = await PublicKey.findProgramAddress([
            Buffer.from('statistic')
          ], new PublicKey(CONFIG.STAKING.PROGRAM_ID));

          const getPool = await program.account.pool.fetch(pool);
          const getStatistic = await program.account.statistic.fetch(statistic);

          const ownerTokenCount = await getAssociatedTokenAddress(new PublicKey(CONFIG.TokenAddress), anchorWallet.publicKey)
          const ownerTokenInfo: any = await connection.getParsedAccountInfo(ownerTokenCount)
          const tokenCount = ownerTokenInfo?.value?.data?.parsed?.info?.tokenAmount?.uiAmount

          let getDailyYield = 0;

          for (let i = 0; i < stakedNfts.length; i++) {
            const getTx: any = await getRewardAmount(anchorWallet, connection, stakedNfts[i].mint)
            getDailyYield += getTx?.dailyYield
          }

          setStakingInfo({
            ...stakingInfo,
            totalCount: getStatistic.stakedCount,
            ownerCount: getPool.stakedCount,
            dailyYield: getDailyYield ? getDailyYield : 0

          })

          let totalReward = 0

          for (let i = 0; i < stakedNfts.length; i++) {
            const getTx: any = await getRewardAmount(anchorWallet, connection, stakedNfts[i].mint)
            totalReward += getTx?.totalReward;
          }

          const tokenBalance = totalReward / CONFIG.DECIMAL
          setCoodeTokenBalance(tokenBalance);

          setInterval(async () => {

            let totalReward = 0;

            for (let i = 0; i < stakedNfts.length; i++) {
              const getTx: any = await getRewardAmount(anchorWallet, connection, stakedNfts[i].mint)
              totalReward += getTx?.totalReward;
            }

            const tokenBalance = totalReward / CONFIG.DECIMAL
            setCoodeTokenBalance(tokenBalance);

          }, 5000);

          const getCollect = await axios.get(MaginEdenCollectionApi)

          const floorPrice = getCollect?.data?.floorPrice / CONFIG.DECIMAL
          const solNet: any = await commonService({
            method: `get`,
            route: GET_SOLALA_INFO
          })
          const usdPrice = solNet?.priceUsdt
          setSolUsdFloorPrice(floorPrice * usdPrice)

          setLoading(false)
        } catch (error) {

          setLoading(false)

        }
      }
    )()
  }, [anchorWallet?.publicKey])

  useEffect(() => {
    (
      async () => {
        try {
          const tempWallet: any = anchor.web3.Keypair.generate().publicKey;
          const provider = new anchor.Provider(connection, tempWallet, {
            skipPreflight: true,
            preflightCommitment: 'confirmed' as Commitment,
          } as ConnectionConfig)
          const programNft = new anchor.Program(CONFIG.STAKING.IDL, new PublicKey(CONFIG.STAKING.PROGRAM_ID), provider);
          let [statisticPool]: any = await anchor.web3.PublicKey.findProgramAddress(
            [Buffer.from(CONFIG.STAKING.STATISTIC)],
            new PublicKey(CONFIG.STAKING.PROGRAM_ID)
          );
          const statisticData = await programNft.account.statistic.fetch(statisticPool)

          setStakingInfo({
            ...stakingInfo,
            totalCount: statisticData.stakedCount,
          })

        } catch (error) {

        }
      }
    )()


  }, [])

  return (
    <div className="pb-10">
      {
        isLoading ?
          <div id="preloader"></div> :
          <div id="preloader" style={{ display: "none" }}></div>
      }
      <Navbar />
      <StakingMenus />
      <div>
        <div className="m-auto max-w-[1290px]">
          <div className="flex justify-between">
            {
              anchorWallet ? <div className="basis-[72%]">
                <StakingComponent
                  isLoading={isLoading}
                  setLoading={setLoading}
                  stakedNfts={stakedNfts}
                  setStakedNfts={setStakedNfts}
                  unStakedNfts={unStakedNfts}
                  setUnstakedNfts={setUnstakedNfts}
                  stakingInfo={stakingInfo}
                  setStakingInfo={setStakingInfo}
                  coodeTokenBalance={coodeTokenBalance}
                />
                <UnStakingComponent
                  isLoading={isLoading}
                  setLoading={setLoading}
                  stakedNfts={stakedNfts}
                  setStakedNfts={setStakedNfts}
                  unStakedNfts={unStakedNfts}
                  setUnstakedNfts={setUnstakedNfts}
                  stakingInfo={stakingInfo}
                  setStakingInfo={setStakingInfo}

                />
                <div className="flex items-center justify-end mt-8">
                  <button
                    onClick={handleUnStakeAll}
                    type="button"
                    className={`${stakedNfts.length > 0 ? `cursor-pointer opacity-100 ` : `opacity-80 cursor-no-drop  `} bg-white text-base py-3 px-5 rounded-md`}
                  >
                    UnStake All
                  </button>
                  <button
                    onClick={handleStakeAll}
                    type="button"
                    className={`${unStakedNfts.length > 0 ? `cursor-pointer opacity-100 ` : `opacity-80 cursor-no-drop  `} bg-white text-base py-3 px-5 ml-4 rounded-md`}
                  >
                    Stake All
                  </button>
                  <button
                    onClick={handleClaim}
                    type="button"
                    className={` ${stakedNfts.length > 0 ? `cursor-pointer opacity-100 ` : `opacity-80 cursor-no-drop  `} bg-white text-base py-3 px-5 ml-4 rounded-md`}
                  >
                    Claim
                  </button>
                </div>
              </div> :

                <div className="basis-[72%]" >

                </div>
            }

            <div className="basis-[26%]">
              <div className=" py-6">
                <h1 className="text-white text-2xl mb-4">Staking Stats</h1>
                <div className="border border-white rounded-lg bg-[#73737359]">
                  <div className="dasboard-clip-head-1 py-1 max-w-[70%]  m-auto bg-white text-center">
                    <h1 className="text-center text-xl">Global Stats</h1>
                  </div>
                  <div className="text-center flex items-center justify-center py-3">
                    <img
                      src={LockedIcon}
                      alt="LockedIcon"
                      className="max-w-[34px]"
                    />
                  </div>
                  <div className="border-black bg-[#949494] border mb-3 flex overflow-hidden">
                    <p className="bg-white text-base text-center basis-[77%] py-1 pl-2 pr-4 para-clip">
                      Number of COODE Staked
                    </p>
                    <p className="py-1 text-center px-2 text-base basis-[23%] bg-[#949494]  text-white">
                      {stakingInfo.totalCount}
                    </p>
                  </div>
                  <div className="text-center flex items-center justify-center py-3">
                    <img
                      src={DollarIcon}
                      alt="LockedIcon"
                      className="max-w-[34px]"
                    />
                  </div>
                  <div className="border-black bg-[#949494] mb-6 border flex overflow-hidden">
                    <p className="bg-white text-base text-center basis-[70%] py-1 pl-2 pr-4 para-clip">
                      Value Locked
                    </p>
                    <p className="py-1 text-center px-2 text-base basis-[30%] bg-[#949494]  text-white">
                      $ {(solUsdPriceFloor * stakingInfo.totalCount).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>

              {
                anchorWallet &&
                <div className=" pt-20">
                  <div className="border border-white rounded-lg bg-[#73737359] overflow-hidden">
                    <div className="dasboard-clip-head-1 py-1 max-w-[70%]  m-auto bg-white text-center">
                      <h1 className="text-center text-xl">Personal Stats</h1>
                    </div>
                    <div className="text-center flex items-center justify-center py-3">
                      <img
                        src={LockedIcon}
                        alt="LockedIcon"
                        className="max-w-[34px]"
                      />
                    </div>
                    <div className="border-black bg-[#949494] border mb-3 flex overflow-hidden">
                      <p className="bg-white text-base text-center basis-[77%] py-1 pl-2 pr-4 para-clip">
                        Number of COODE Staked
                      </p>
                      <p className="py-1 text-center px-2 text-base basis-[23%] bg-[#949494]  text-white">
                        {stakingInfo.ownerCount}
                        { }
                      </p>
                    </div>
                    <div className="text-center flex items-center justify-center py-3">
                      <img
                        src={WalletIcon}
                        alt="LockedIcon"
                        className="max-w-[34px]"
                      />
                    </div>
                    <div className="border-black bg-[#949494] border flex overflow-hidden">
                      <p className="bg-white text-base text-center basis-[70%] py-1 pl-2 pr-4 para-clip">
                        $COODE Balance
                      </p>
                      <p className="py-1 text-center px-2 text-base basis-[30%] bg-[#949494]  text-white">
                        {coodeTokenBalance ? Number(coodeTokenBalance).toFixed(2) : 0}
                      </p>
                    </div>
                    <div className="text-center flex items-center justify-center py-3">
                      <img
                        src={YieldIcons}
                        alt="LockedIcon"
                        className="max-w-[34px]"
                      />
                    </div>
                    <div className="border-black bg-[#949494] border mb-6 flex overflow-hidden">
                      <p className="bg-white text-base text-center basis-[50%] py-1 pl-2 pr-4 para-clip">
                        Daily Yield
                      </p>
                      <p className="py-1 text-center px-2 text-base basis-[50%] bg-[#949494]  text-white">
                        {stakingInfo.dailyYield ? stakingInfo.dailyYield : 0} $COODE/Day
                      </p>
                    </div>
                  </div>
                </div>
              }
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
