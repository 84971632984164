import { useState, useEffect } from "react"
import { useAnchorWallet } from "@solana/wallet-adapter-react"
import { toast } from "react-hot-toast"

import Navbar from "../../components/Navbar"
import StakingMenus from "../../components/StakingMenus"
import { datetimeLocal } from "../../utils"

import commonService from "../../services/common.service"
import CONFIG from "../../config"
import { ProfileValidation } from "../../services/profileValidation"


const Profile = () => {
  const anchorWallet = useAnchorWallet();
  const [isLoading, setLoading] = useState(false);
  const [isCreated, setCreated] = useState(false)

  const [profile, setProfile] = useState<any>({
    birth: new Date(),
    dreamNft: ``,
    dreamVacation: ``,
    travel: ``,
    watchKind: ``,
    petKind: ``
  })


  const handleSubmit = async () => {
    try {
      if (!anchorWallet) {
        toast.error(`Please connect your wallet!`)
        return
      }

      const validation = ProfileValidation(profile)
      if (!validation) return

      setLoading(true)

      if (isCreated) {
        const payload = {
          id: anchorWallet.publicKey.toBase58(),
          birthday: profile.birth,
          dreamNft: profile.dreamNft,
          dreamvacation: profile.dreamVacation,
          travel: profile.travel,
          kindWatch: profile.kindWatch,
          kindPet: profile.kindPet,
          walletAddress: anchorWallet.publicKey.toBase58()
        }

        const res = await commonService({
          method: `post`,
          route: `${CONFIG.API_URL}/profile/${anchorWallet.publicKey.toBase58()}`,
          data: payload
        })

        if (res) {
          toast.success('Successfully Updated')
          setLoading(false)
        } else {
          toast.error(`Error`)
          setLoading(false)
        }

      } else {
        const payload = {
          id: anchorWallet.publicKey.toBase58(),
          birthday: profile.birth,
          dreamNft: profile.dreamNft,
          dreamvacation: profile.dreamVacation,
          travel: profile.travel,
          kindWatch: profile.watchKind,
          kindPet: profile.petKind,
          walletAddress: anchorWallet.publicKey.toBase58()
        }

        const res = await commonService({
          method: `post`,
          route: `${CONFIG.API_URL}/profile`,
          data: payload
        })

        if (res) {
          toast.success('Successfully Created')
          setCreated(true)
          setLoading(false)

        } else {
          toast.error(`Error`)
          setLoading(false)
        }
      }

    } catch (error) {
      toast.error(`Error`)
      setLoading(false)
    }
  }

  useEffect(() => {
    (
      async () => {
        try {
          if (!anchorWallet) {
            setProfile({
              ...profile,
              birth: new Date(),
              dreamNft: '',
              dreamVacation: '',
              travel: '',
              watchKind: '',
              petKind: ''
            })
            return
          }
          setLoading(true)
          const getProfileInfo: any = await commonService({
            method: `get`,
            route: `${CONFIG.API_URL}/profile/${anchorWallet?.publicKey.toBase58()}`
          })
          if (getProfileInfo) {
            setProfile({
              ...profile,
              birth: new Date(getProfileInfo.birthday),
              dreamNft: getProfileInfo.dreamNft,
              dreamVacation: getProfileInfo.dreamvacation,
              travel: getProfileInfo.travel,
              watchKind: getProfileInfo.kindWatch,
              petKind: getProfileInfo.kindPet
            })
            setCreated(true)
          } else {
            setProfile({
              ...profile,
              birth: new Date(),
              dreamNft: '',
              dreamVacation: '',
              travel: '',
              watchKind: '',
              petKind: ''
            })
          }
          setLoading(false)

        } catch (error) {

          setLoading(false)
        }
      }
    )()
  }, [anchorWallet])

  return (
    <>
      <div>
        {
          isLoading ?
            <div id="preloader"></div> :
            <div id="preloader" style={{ display: "none" }}></div>
        }
        <Navbar />
        <StakingMenus />
        <div className='w-[90%] md:w-[693px] mx-[auto] my-0 bg-[#73737335] my-[49px] rounded-[7px] border-[3px] borer-solid border-[#FFFFFF]  ' >
          <div className="bg-white w-[50%] my-0 mx-[auto] para-clip-personal " >
            <p className="text-black w-full text-center text-[28px]  " >Personal Information </p>
          </div>
          <div className='flex flex-col gap-[25px] mt-[35px] px-[29px] pb-[44px]' >
            <div className='flex flex-col gap-[6px]'  >
              <p className='text-white' >When is your birthday?</p>
              <div className="relative">
                <input
                  className='bg-white w-full rounded-[10px] border-[2px]  border-solid border-[#606060] placeholder:text-[#606060] py-[8px] px-[16px] '
                  type="datetime-local"
                  value={datetimeLocal(profile.birth)}
                  onChange={(e) =>
                    setProfile({
                      ...profile,
                      birth: new Date(e.target.value),
                    })
                  }
                />
              </div>
            </div>

            <div className='flex flex-col gap-[6px]' >
              <p className='text-white' >What is your dream NFT for 2023?</p>
              <div className="relative">
                <input
                  className='bg-white w-full rounded-[10px] border-[2px]  border-solid border-[#606060] placeholder:text-[#606060] py-[8px] px-[16px] '
                  placeholder="Type your answer here!"
                  value={profile.dreamNft}
                  onChange={(e) =>
                    setProfile({
                      ...profile,
                      dreamNft: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className='flex flex-col gap-[6px]' >
              <p className='text-white' >What is your dream vacation destination in 2023?</p>
              <div className="relative">
                <input
                  className='bg-white w-full rounded-[10px] border-[2px]  border-solid border-[#606060] placeholder:text-[#606060] py-[8px] px-[16px] '
                  placeholder="Type your answer here!"
                  value={profile.dreamVacation}
                  onChange={(e) =>
                    setProfile({
                      ...profile,
                      dreamVacation: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className='flex flex-col gap-[6px]' >
              <p className='text-white' >Who would you like to travel with?</p>
              <div className="relative">
                <input
                  className='bg-white w-full rounded-[10px] border-[2px]  border-solid border-[#606060] placeholder:text-[#606060] py-[8px] px-[16px] '
                  placeholder="Type your answer here!"
                  value={profile.travel}
                  onChange={(e) =>
                    setProfile({
                      ...profile,
                      travel: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className='flex flex-col gap-[6px]' >
              <p className='text-white' >What kind of watch would you like to own in 2023?</p>
              <div className="relative">
                <input
                  className='bg-white w-full rounded-[10px] border-[2px]  border-solid border-[#606060] placeholder:text-[#606060] py-[8px] px-[16px] '
                  placeholder="Type your answer here!"
                  value={profile.watchKind}
                  onChange={(e) =>
                    setProfile({
                      ...profile,
                      watchKind: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className='flex flex-col gap-[6px]' >
              <p className='text-white' >What kind of pet would you like get in 2023, and what would you name it?</p>
              <div className="relative">
                <input
                  className='bg-white w-full rounded-[10px] border-[2px]  border-solid border-[#606060] placeholder:text-[#606060] py-[8px] px-[16px] '
                  placeholder="Type your answer here!"
                  value={profile.petKind}
                  onChange={(e) =>
                    setProfile({
                      ...profile,
                      petKind: e.target.value,
                    })
                  }
                />
              </div>
            </div>

          </div>
        </div>
        <div className="bg-white max-w-fit my-0 mx-[auto] text-black rounded-[4px] py-[12px] px-[24px] mt-[-72px] mb-[80px] cursor-pointer "
          onClick={handleSubmit}
        >
          Submit
        </div>

      </div>

    </>

  )
}

export default Profile