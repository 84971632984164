import React from "react";
import { Link, useLocation } from "react-router-dom";

const StakingMenus: React.FC = () => {
  const activeLink = useLocation();
  return (
    <div className="sm:px-4 px-2">
      <div className="sm:mt-12 mt-8 flex justify-end max-w-[1280px] m-auto">
        <div className="flex justify-between items-center max-w-3xl w-full">
          <div className="w-[300px] border bg-white rounded-[0.7rem] p-[1px]">
            <div className="rounded-[0.7rem]">
              <div className="flex items-center justify-between text-white text-base">
                <Link
                  to="/"
                  className={`${activeLink.pathname === "/"
                    ? " transition duration-75 btn-background basis-[49%] text-center py-3 rounded-[0.7rem] bg-black"
                    : "duration-75 transition basis-[49%] text-center text-black py-3 rounded-[0.7rem]"
                    }`}
                >
                  Staking
                </Link>
                <Link
                  to="/dashboard"
                  className={`${activeLink.pathname === "/dashboard"
                    ? " transition duration-75 btn-background basis-[49%] text-center py-3 rounded-[0.7rem] bg-black"
                    : "duration-75 transition basis-[49%] text-center text-black py-3 rounded-[0.7rem]"
                    }`}
                >
                  Dashboard
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakingMenus;
