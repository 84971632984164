import { useRef } from "react";
import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import Chevron from "../../assets/Chevron.png";
import { getRewardAmount, stakeNft } from "../../services/contracts/staking";
import { confirmTx } from "../../helper/composables/sol/confirm";
import { toast } from "react-hot-toast";

const UnStakingComponent = (props: any) => {
  const {
    unStakedNfts,
    setLoading,
    stakedNfts,
    setStakedNfts,
    setUnstakedNfts,
    stakingInfo,
    setStakingInfo
  } = props
  const anchorWallet: any = useAnchorWallet()
  const { connection } = useConnection()
  const swiperRefTwo: any = useRef();

  const sliderSettings = {
    240: {
      slidesPerView: 1,
      spaceBetween: 16,
      pagination: false,
    },
    680: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    1024: {
      slidesPerView: 4,
      spaceBetween: 16,
    },
  };

  const handleStake = async (mint: string, idx: number) => {
    try {
      setLoading(true)
      const selectNft = unStakedNfts.filter((item: any) => mint === item.mint);

      const getTx: any = await stakeNft(anchorWallet, connection, [mint])
      const rewardInfo = await getRewardAmount(anchorWallet, connection, mint)
      console.log('rewardInfo', rewardInfo)
      const res = await confirmTx(getTx, anchorWallet, connection);

      if (res) {
        const remain_nft = unStakedNfts.filter((item: any) => mint !== item.mint);
        setStakedNfts([...stakedNfts, ...selectNft]);
        setUnstakedNfts([...remain_nft])
        setStakingInfo({
          ...stakingInfo,
          totalCount: stakingInfo.totalCount + 1,
          ownerCount: stakingInfo.ownerCount + 1,
          dailyYield: stakingInfo.dailyYield + rewardInfo?.dailyYield
        })
        toast.success('Successfully Staked')
      } else {
        toast.error("Error Staking.")
      }

      setLoading(false)
    } catch (error) {
      console.log(`staking error in handlestake`, error);

      toast.error("Error Staking.")
      setLoading(false)
    }
  }

  return (
    <div className="py-6">
      <h1 className="text-white text-2xl mb-4">UnStaked NFTs</h1>
      <div className="m-auto max-w-[830px]">
        <div className="relative min-h-[216px] ">
          {
            unStakedNfts.length > 4 && <button
              onClick={() => swiperRefTwo.current?.slidePrev()}
              className="absolute top-[43%] left-[-52px]"
            >
              <img
                src={Chevron}
                alt="RightChevron"
                className="max-w-[36px]"
              />
            </button>
          }

          <Swiper
            slidesPerView={4}
            spaceBetween={16}
            breakpoints={sliderSettings}
            onBeforeInit={(swiper) => {
              swiperRefTwo.current = swiper;
            }}
            speed={1200}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Navigation, Autoplay]}
            className="mySwiper"
          >

            {unStakedNfts.length > 4 ? (
              unStakedNfts.map((item: any, index: any) => {
                return (
                  <SwiperSlide key={index}>
                    <div className=" border border-white rounded-lg bg-[#73737359]">
                      <img
                        src={item.metadata ? item?.metadata?.image : ``}
                        className="min-h-[193px]"
                        onError={(e) => {
                          e.currentTarget.src = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTP-3QSHNAEBzr6s2fvf7hhOpnt0HGHthvDoGqFF3XQHg&s"
                        }}
                        alt="Coode"
                      />
                      <div className="border-black bg-[#949494] border mb-8 flex overflow-hidden">
                        <p className="bg-white text-base text-center basis-[50%] py-1 pl-2 pr-4 para-clip">
                          Token ID
                        </p>
                        <p className="py-1 text-center px-2 text-base basis-[50%] bg-[#949494]  text-white">
                          #{item.data.name.split('#')[1]}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center justify-center -mt-5">
                      <button
                        onClick={() => handleStake(item.mint, index)}
                        type="button"
                        className="py-2 px-8 bg-white rounded-md"
                      >
                        Stake
                      </button>
                    </div>
                  </SwiperSlide>
                );
              })
            ) :
              unStakedNfts.length > 0 ?
                <div className="flex items-center gap-8" >
                  {unStakedNfts.map((item: any, index: any) => {
                    return (
                      <div className="basis-[24%]" key={index} >
                        <div className=" border border-white rounded-lg bg-[#73737359]">
                          <img
                            src={item?.metadata ? item?.metadata.image : ``}
                            className="min-h-[193px]"
                            onError={(e) => {
                              e.currentTarget.src = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTP-3QSHNAEBzr6s2fvf7hhOpnt0HGHthvDoGqFF3XQHg&s"
                            }}
                            alt="Coode"
                          />
                          <div className="border-black bg-[#949494] border mb-8 flex overflow-hidden">
                            <p className="bg-white text-base text-center basis-[50%] py-1 pl-2 pr-4 para-clip">
                              Token ID
                            </p>
                            <p className="py-1 text-center px-2 text-base basis-[50%] bg-[#949494]  text-white">
                              #{item.data.name.split('#')[1]}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center justify-center -mt-5">
                          <button
                            onClick={() => handleStake(item.mint, index)}
                            type="button"
                            className="py-2 px-8 bg-white rounded-md"
                          >
                            Stake
                          </button>
                        </div>
                      </div>

                    );
                  })}
                </div>
                :
                <div className="w-full absolute top-[50%] text-center mt-4 text-white" >No Data found</div>

            }
          </Swiper>

          {
            unStakedNfts.length > 4 &&
            <button
              onClick={() => swiperRefTwo.current?.slideNext()}
              className="absolute top-[43%] right-[-52px]"
            >
              <img
                src={Chevron}
                alt="RightChevron"
                className="max-w-[36px] rotate-180"
              />
            </button>
          }

        </div>

      </div>
    </div>
  )
}

export default UnStakingComponent